<template>
   <div>
       <div class="container1 flexRow">
           Alternative support options
        </div>
   </div> 
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>